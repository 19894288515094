import React from "react"
import { graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo";
import DetailsHead from "../components/detailshead"
import PageItem from '../components/blogs/post-item'
import Filter from '../components/blogs/filter'
import styles from '../components/blogs/listing.module.css'

const Product = ({data}) => {
	console.log(data);
	let blogs = [];
	data.strapiBlogCategories.blogs.forEach((item)=>{
		blogs.push(item);
	})
	return(
		<Layout>
	    <SEO title={`${data.strapiBlogCategories.name} | Car LockSmith Service Provider`}/>
	    <DetailsHead title={data.strapiBlogCategories.name}/>
	    <div className={styles.wrapper}>
	    	<Filter/>
	   	</div>
	    <div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.itemsWrapper}>
			      	{
			      		blogs.map((node, index) =>{
			      			return(
			      				<PageItem key={node.id} styles={styles} item={node}/>
			      			)
			      		})
			    	}
			    </div>
			  </div>
			</div>
		</Layout>
	)
}

export default Product

export const query = graphql`
query BlogCategoryTemplate($slug: String!) {
	strapiBlogCategories(slug: { eq: $slug }) {
		id
        name
        slug
        blogs {
          created_at
          id
          name
          excerpt
          slug
          feature_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
	}
}
` 

